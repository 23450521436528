import React, { useState } from 'react'
import { Slider } from 'antd'
import QuestionsHeader from '@components/QuestionsHeader/QuestionsHeader'
import Button from '@components/Button/Button'

function UseCase3(props) {
  const [inputValue1, setInputValue1] = useState(props?.data?.slider1)
  const [inputValue2, setInputValue2] = useState(props?.data?.roi1)
  const [inputValue3, setInputValue3] = useState(props?.data?.roi2)
  const onChange1 = (value) => {
    setInputValue1(value)
  }
  const onChange2 = (value) => {
    setInputValue2(value)
  }
  const onChange3 = (value) => {
    setInputValue3(value)
  }
  const handlerData = () => {
    let objectData = props.data
    objectData.slider1 = inputValue1
    objectData.roi1 = inputValue2
    objectData.roi2 = inputValue3
    props.handleData(objectData)
  }
  const handleNextPage = () => {
    handlerData()
    return props.next()
  }
  const handleBackPage = () => {
    handlerData()
    return props.back()
  }
  const marks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: '10',
  }

  return (
    <>
      <QuestionsHeader titleHead="Visualisation and Design Checks" />
      <div className="survey-slider__questions-wrapper">
        <div className="survey-slider__title">Question #1</div>
        <div className="survey-slider__question headingLegendBold">
          Which of the following best describes your current process of
          reporting and managing BIM clash issues?
        </div>
        <div className="survey-slider__textBody">
          <div className="survey-slider__textHeader">
            <div className="">1. being:</div>
            <div className="survey-slider__subTitle">
              Manually capturing the issues and screenshot the clashes and
              sending them. Reports on clashes can only highlight a maximum of 2
              disciplines at one time. Assignee of clashes cannot visualize
              exact location in 3D model that assigner is trying to show and
              resolution tracking is done via emails
            </div>
          </div>
          <div className="survey-slider__textHeader">
            <div className="">10. being:</div>
            <div className="survey-slider__subTitle ">
              Utilize digital 3D models in the cloud to seek feedback and
              validate design aspects, space requirements, and other settings
              such as buildability and constructability using a cloud platform.
              Clashes are assigned directly in the cloud to stakeholders with a
              category of root cause. Assignee of clashes in BIM collaboration
              can be brought to the exact location of the cloud to stakeholders
              with a category of root cause. A clear overview on resolutions is
              provided through a central issue management
            </div>
          </div>
        </div>
        <div className="survey-slider__slider">
          <Slider
            min={1}
            max={10}
            marks={marks}
            onChange={onChange1}
            value={typeof inputValue1 === 'number' ? inputValue1 : 0}
          />
        </div>
        <div className="survey-slider__buttons-wrapper">
          <Button onClick={handleBackPage} label="Previous Case" />
          <div className="horizontal-space" />
          <Button onClick={handleNextPage} label="Next" />
        </div>
      </div>
    </>
  )
}

export default UseCase3
